<ng-container *transloco="let t; read: 'nucleus.account-details.views.account-details-overview'">
  <div class="flex flex-col pt-6" *ngIf="details$ | appWithLoading | async as details">
    <h2 class="mat-title-1" data-testid="account-details-header">{{t('header')}}</h2>

    <ng-container *ngIf="details.loading; else detailsContent">
      <hxp-kernel-form-field-ghost class="input" [hideGhost]="false" />
      <hxp-kernel-form-field-ghost class="input" [hideGhost]="false" />
    </ng-container>

    <ng-template #detailsContent>
      <div class="grid grid-cols-1" *ngIf="details.value as data">
        <mat-form-field class="input" hyFormField>
          <mat-label>{{ t('account-name-label') }}</mat-label>
          <input matInput [ngModel]="data.name" readonly type="text" />
        </mat-form-field>
        <mat-form-field class="input" hyFormField>
          <mat-label>{{ sourceAccountSystemDescriptions[data.sourceAccountSystem] || data.sourceAccountSystem }}</mat-label>
          <input matInput [ngModel]="data.sourceAccountId" readonly type="text" />
        </mat-form-field>
        <mat-form-field class="input" hyFormField *ngIf="data.hsiNumber">
          <mat-label>{{ t('hsi-number-label') }}</mat-label>
          <input matInput [ngModel]="data.hsiNumber" readonly type="text" />
        </mat-form-field>
      </div>
    </ng-template>
  </div>
</ng-container>
