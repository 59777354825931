import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GhostModule, KeyboardNavListModule } from '@hxp/kernel';
import {
  HyContentListModule,
  HyDialogModule,
  HyDualListModule,
  HyGhostModule,
  HyMaterialModule,
  HySearchInputModule,
  HyTranslateModule,
} from '@hyland/ui';
import { AccountAccessRoutingModule } from './account-access-routing.module';
import { AccountAppListView } from './account-app-list/account-app-list.view';
import { ManageRoleUserGroupsDialog } from './manage-role-user-groups-dialog/manage-role-user-groups.dialog';
import { RoleMappingsComponent } from './role-mappings/role-mappings.component';
import { UserRightsView } from './user-rights/user-rights.view';

@NgModule({
  declarations: [UserRightsView, RoleMappingsComponent, ManageRoleUserGroupsDialog, AccountAppListView],
  exports: [RoleMappingsComponent, ManageRoleUserGroupsDialog],
  imports: [
    CommonModule,
    AccountAccessRoutingModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    MatTabsModule,
    MatTooltipModule,
    MatCardModule,
    MatInputModule,
    FormsModule,
    MatFormFieldModule,
    MatSortModule,
    HyContentListModule,
    MatButtonModule,
    HyDualListModule,
    HyDialogModule,
    HySearchInputModule,
    HyGhostModule,
    HyMaterialModule,
    HyTranslateModule,
    GhostModule,
    KeyboardNavListModule,
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class AccountAccessModule {}
