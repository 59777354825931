import { HyShellToolbarService } from '@hyland/ui-shell';
import { ToolbarService } from './shell-toolbar.service';

export const TOOLBAR_SERVICE_OVERRIDE = {
  provide: HyShellToolbarService,
  useFactory: (toolbarService: ToolbarService) => ({
    toolbarEnabled$: toolbarService.visible$,
  }),
  deps: [ToolbarService],
};
