import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { HY_WINDOW, HyAuthService } from '@hyland/ui';
import { filter, from, map, take } from 'rxjs';
import { ANALYTICS_CONFIG, Analytics, AnalyticsConfig } from '../analytics';
import { anonymizeString, sanitizeUrl } from '../utils/analytics.utils';
import { BaseUserInfo, hasBaseUserInfo } from './base-user-info';

type PendoWindow = Window & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pendo: any;
};

@Injectable()
export class PendoAnalyticsService implements Analytics {
  initialized = false;

  constructor(
    private readonly _auth: HyAuthService,
    @Inject(HY_WINDOW) private readonly _window: PendoWindow,
    @Inject(DOCUMENT) private readonly _document: Document,
    @Inject(ANALYTICS_CONFIG) private readonly analyticsConfig?: AnalyticsConfig,
  ) {}

  initialize() {
    // Pendo initialization requires the user's ID, hence it's essential to ensure user authentication prior to the initialization process.
    this._auth.authenticated$
      .pipe(
        filter((isAuthenticated) => isAuthenticated),
        take(1),
        map(() => from(this._setupPendo())),
      )
      .subscribe();
  }

  private async _setupPendo() {
    if (!this.analyticsConfig?.enable || this.initialized) {
      return;
    }

    const { disableGuides, pendoTrackingId } = this.analyticsConfig.pendoConfig;

    if (!pendoTrackingId) {
      throw new Error('PENDO_API_KEY token was not provided');
    }

    const profile = await this._auth.getUserProfile();

    if (hasBaseUserInfo(profile)) {
      this._injectPendo(pendoTrackingId);
      this._initializePendo(profile, disableGuides);
    }
  }

  private _initializePendo(profile: BaseUserInfo, disableGuides: boolean) {
    this.initialized = true;

    this._window.pendo.initialize({
      sanitizeUrl: (url: string) => sanitizeUrl(url),

      visitor: {
        id: anonymizeString(profile.sub),
      },

      account: {
        id: profile.hxp_account,
      },

      disableGuides,
    });
  }

  /**
   * The code is almost 1 to 1 copy paste from official pendo starting guide
   *
   * for Pendo script to load correctly the below CSP must be added
   * script-src: https://cdn.pendo.io
   */
  /* prettier-ignore */
  private _injectPendo(analyticsKey: string) {
    // the prettier is breaking eslint rule `Unexpected space before function parentheses.`
    // and there is no fix fot that on prettier config side
    /* prettier-ignore */

    // disable coverage for pendo code
    /* istanbul ignore next */
    ((apiKey: string) => {
      (function(p, e, o) {
        let w;
        let x;
        o = p.pendo = p.pendo || {};
        o._q = o._q || [];
        const v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
        for (w = 0, x = v.length; w < x; ++w) {
          (function(m) {
            o[m] = o[m] || function() {
              // eslint-disable-next-line prefer-rest-params
              o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
            };
          })(v[w]);
        }
        const y = e.createElement('script');
        y.async = !0;
        y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
        const z = e.getElementsByTagName('script')[0];
        z.parentNode?.insertBefore(y, z);
      })(this._window, this._document, this._window.pendo);
    }).bind(this)(analyticsKey);
  }
}
