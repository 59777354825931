import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { HyMaterialIconModule, HyTranslateService } from '@hyland/ui';
import { TranslocoModule } from '@jsverse/transloco';

interface EmptyState {
  text: string;
  icon?: string;
}

export interface EmptyStateConfig {
  emptyStateDefaults: EmptyState;
}

@Component({
  standalone: true,
  selector: 'hxp-kernel-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
  imports: [CommonModule, MatIconModule, HyMaterialIconModule, RouterModule, TranslocoModule],
})
export class EmptyStateComponent implements EmptyState {
  @Input()
  icon?: string;

  @Input()
  text!: string;

  @Input()
  title?: string;

  constructor(
    private readonly _route: ActivatedRoute,
    private readonly _translateService: HyTranslateService,
  ) {
    // If this component is used as a routed-to component, check if
    // the inputs were supplied via the data property
    const routeData = this._route.snapshot.data as EmptyStateConfig;

    if (routeData && routeData.emptyStateDefaults && routeData.emptyStateDefaults.icon) {
      this.text = this._translateService.get(routeData.emptyStateDefaults.text);
      this.icon = routeData.emptyStateDefaults.icon;
    }
  }
}
