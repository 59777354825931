import { inject, Injectable, Injector } from '@angular/core';
import { HyAuthService, HyBrowserRedirectAuthService } from '@hyland/ui';
import { FakeAuthService } from './fake-auth.service';

@Injectable({ providedIn: 'root' })
export class AuthServiceLocator {
  private readonly _injector = inject(Injector);

  getAuthService(useFake: boolean): HyAuthService {
    return useFake ? this._injector.get(FakeAuthService) : this._injector.get(HyBrowserRedirectAuthService);
  }
}
