import { CommonModule, SlicePipe, UpperCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { HyGhostModule } from '@hyland/ui';
import { TranslocoModule } from '@jsverse/transloco';

const COLOR_CLASSES = [
  'hy-functional-text-purple-bg',
  'hy-functional-text-blue-bg',
  'hy-functional-text-teal-bg',
  'hy-functional-text-green-bg',
  'hy-functional-text-yellow-bg',
  'hy-functional-text-orange-bg',
  'hy-functional-text-red-bg',
  'hy-functional-text-pink-bg',
];

@Component({
  selector: 'hxp-portal-app-tile',
  standalone: true,
  imports: [CommonModule, SlicePipe, UpperCasePipe, TranslocoModule, HyGhostModule],
  templateUrl: './app-tile.component.html',
  styleUrls: ['./app-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppTileComponent {
  @Input()
  get appName(): string {
    return this._appName;
  }
  set appName(appName: string) {
    this._appName = appName;
    this.colorClass = this.getColorClass(appName);
  }
  private _appName!: string;

  @Input()
  launchUrl?: string | null;

  @Input()
  loading = false;

  colorClass!: string;
  pressed = false;

  getColorClass(appName: string) {
    const text = appName.toLowerCase();

    if (!appName.length) {
      return COLOR_CLASSES[0];
    }

    const charactersSum = (text.charCodeAt(0) || 0) + (text.charCodeAt(1) || 0);
    const colorIndex = charactersSum % COLOR_CLASSES.length;

    return COLOR_CLASSES[colorIndex];
  }

  setPressState(pressed: boolean) {
    this.pressed = pressed;
  }
}
