<ng-container *transloco="let t; read: 'nucleus.account-details.views.account-details-subscriptions'">
  <mat-sidenav-container class="static h-full bg-white -mx-6 mb-0" [hasBackdrop]="false">
    <mat-sidenav
      mode="over"
      [opened]="subDetailOutlet.isActivated"
      class="w-full max-w-[600px] mat-elevation-z0 border-l border-foreground-divider"
      position="end"
      [autoFocus]="false"
    >
      <router-outlet #subDetailOutlet="outlet"></router-outlet>
    </mat-sidenav>

    <div class="flex flex-col h-full p-6 box-border" *ngIf="subscriptions$ | async as subscriptions">
      <div class="flex flex-row justify-between mb-4 items-center">
        <h2 class="mat-subtitle-1 m-0">{{ t('header') }}</h2>
        <hxp-kernel-on-off-switch
          *ngIf="state() !== 'empty'"
          (valueChange)="onInactiveSubsToggleChange($event)"
          [checked]="initialToggleCheckedValue"
          [label]="t('inactive-subscriptions-toggle-text')"
        ></hxp-kernel-on-off-switch>
      </div>

      <ng-container>
        <hy-content-list
          class="h-full"
          *ngIf="state() !== 'empty'; else noSubs"
          data-testid="subscriptions-list"
          [activeRow]="selectedSub"
          [dataSource]="subscriptionDatasource"
          [sortColumn]="sortColumn"
          [sortDirection]="sortDirection"
          (activeRowClicked)="$event ? handleRowSelection($event) : undefined"
          (activeRowChange)="$event ? handleRowSelection($event) : undefined"
          [loading]="state() === 'loading'"
        >
          <hy-content-list-column propertyName="title" [sortable]="true" label="{{ t('table-headers.subscription-name') }}">
            <ng-container *hyContentListCell="let row">
              {{ row.title }} <hy-tag data-testid="trial-tag" *ngIf="row.isTrial">{{t('trial-tag-text')}}</hy-tag>
            </ng-container>
          </hy-content-list-column>
          <hy-content-list-column propertyName="status" [sortable]="true" label="{{ t('table-headers.status') }}">
            <ng-container *hyContentListCell="let row">
              <hxp-admin-provisioning-status-display
                [provisioningStatus]="row.status"
                [objectType]="row.__typename"
                [environmentName]="row.environmentName"
              ></hxp-admin-provisioning-status-display>
            </ng-container>
          </hy-content-list-column>
          <hy-content-list-column propertyName="environmentName" [sortable]="true" label="{{ t('table-headers.environment') }}">
            <ng-container *hyContentListCell="let row">
              <span *ngIf="row.environmentName; else setup">{{ row.environmentName | titlecase }}</span>
              <ng-template #setup>
                <div class="flex items-center">
                  <mat-icon hyIcon class="alert mr-2" attr.alt="{{ t('unassigned-environment-icon-alt-text') }}">critical</mat-icon>
                  <span class="unassigned-tentant">{{ t('unassigned-environment-text') }}</span>
                </div>
              </ng-template>
            </ng-container>
          </hy-content-list-column>
        </hy-content-list>

        <ng-template #noSubs>
          <mat-list>
            <h3 mat-subheader class="mat-subtitle-2 block text-center">{{ t('no-subscriptions-header') }}</h3>
          </mat-list>
        </ng-template>
      </ng-container>
    </div>
  </mat-sidenav-container>
</ng-container>
