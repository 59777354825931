import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { HyDialogModule, HyMaterialIconModule, configureHyDialogOptions } from '@hyland/ui';
import { TranslocoModule } from '@jsverse/transloco';
import { FeatureFlagsOverrideDialog } from '../../dialogs/feature-flags-override/feature-flags-override.dialog';

@Component({
  selector: 'hxp-kernel-feature-flags',
  standalone: true,
  imports: [CommonModule, HyDialogModule, MatButtonModule, MatIconModule, HyMaterialIconModule, TranslocoModule],
  templateUrl: './feature-flags.component.html',
  styleUrls: ['./feature-flags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureFlagsComponent {
  constructor(private readonly _dialog: MatDialog) {}

  openDialog() {
    this._dialog.open(
      FeatureFlagsOverrideDialog,
      configureHyDialogOptions({
        width: '1200px',
        height: '1000px',
        maxWidth: '80vw',
        maxHeight: '80vh',
        autoFocus: false,
      }),
    );
  }
}
