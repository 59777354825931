import { DOCUMENT } from '@angular/common';
import { Component, inject, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserPortalEnvironmentsGQL } from '@hxp/graphql';
import {
  AppsSwitcherService,
  NamedEntity as EnvironmentBase,
  EnvironmentContextService,
  EnvironmentSelectorDialog,
  EnvironmentSelectorDialogData,
  ShellSettingsService,
} from '@hxp/kernel';
import { AnalyticsFacade } from '@hxp/shared/analytics';
import { configureHyDialogOptions, HyAuthService, HyDialogWidth } from '@hyland/ui';
import { Observable, Subject } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'hxp-portal-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly _themingService = inject(ShellSettingsService);

  environments$: Observable<EnvironmentBase[]>;
  selectedEnvironment$?: Observable<EnvironmentBase | undefined>;

  private _environments: Array<EnvironmentBase & { selected: boolean }> = [];
  private readonly _unsubscribe$ = new Subject<void>();
  readonly accountApps$ = this._switcherApps.accountApps$;

  constructor(
    private readonly _dialog: MatDialog,
    @Inject(DOCUMENT) private readonly _doc: Document,
    private readonly _router: Router,
    private readonly _environmentContext: EnvironmentContextService,
    environmentQuery: UserPortalEnvironmentsGQL,
    readonly hyAuthService: HyAuthService,
    private readonly _analytics: AnalyticsFacade,
    private readonly _switcherApps: AppsSwitcherService,
  ) {
    this.environments$ = environmentQuery.watch().valueChanges.pipe(
      filter((environmentQueryResult) => !!environmentQueryResult.data),
      map((environmentQueryResult) => {
        const storedEnvironmentIds: string[] = [];

        return environmentQueryResult.data.currentUser.subscribedApps
          .filter((app) => {
            if (app.environment && storedEnvironmentIds.indexOf(app.environment.id) === -1) {
              storedEnvironmentIds.push(app.environment.id);
              return true;
            }
            return false;
          })
          .map((app) => app.environment!);
      }),
      tap((environmentsFromQuery) => this._updateEnvironmentList(environmentsFromQuery)),
    );
  }

  ngOnInit(): void {
    this.selectedEnvironment$ = this.environments$.pipe(
      switchMap((environments) => {
        return this._environmentContext.selectedEnvironment$.pipe(
          map((selectedEnvironmentsId) => {
            const selectedEnvironment = environments.filter((environment) => environment.id === selectedEnvironmentsId);

            if (selectedEnvironment.length) {
              return {
                id: selectedEnvironmentsId,
                name: selectedEnvironment[0].name,
              };
            } else if (environments.length) {
              this._environmentContext.selectEnvironment(environments[0].id);

              return {
                id: environments[0].id,
                name: environments[0].name,
              };
            }
          }),
        );
      }),
    );

    this._themingService.forceLightTheme();
    this._analytics.initialize();
  }

  switchEnvironment() {
    this._updateEnvironmentList(this._environments);

    this._dialog
      .open<EnvironmentSelectorDialog, { environmentList: EnvironmentSelectorDialogData[] }, EnvironmentSelectorDialogData>(
        EnvironmentSelectorDialog,
        configureHyDialogOptions({
          width: HyDialogWidth.Medium,
          autoFocus: false,
          data: {
            environmentList: this._environments,
          },
        }),
      )
      .afterClosed()
      .subscribe((selectedEnvironment) => {
        if (selectedEnvironment) {
          this._openDashboardInNewWindow(selectedEnvironment.id);
        }
      });
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  private _openDashboardInNewWindow(environmentId: string) {
    const internalUrl = this._router.serializeUrl(this._router.createUrlTree(['environments', environmentId]));

    const currentAbsoluteUrl = this._doc.defaultView?.location.href || '';
    const currentRelativeUrl = this._router.url;
    const indexOfRelativeUrl = currentAbsoluteUrl.indexOf(currentRelativeUrl);
    const baseUrl = currentAbsoluteUrl.substring(0, indexOfRelativeUrl);

    this._doc.defaultView?.open(`${baseUrl}${internalUrl}`, '_blank');
  }

  private _updateEnvironmentList(sourceEnvironmentList: EnvironmentBase[]) {
    const currentlySelectedEnvironmentId = this._environmentContext.getSelectedEnvironmentValue();

    this._environments = sourceEnvironmentList.map((environment) => {
      return {
        id: environment.id,
        name: environment.name,
        selected: environment.id === currentlySelectedEnvironmentId,
      };
    });
  }
}
