import { inject, Injectable } from '@angular/core';
import { HyThemingService } from '@hyland/ui';

@Injectable({
  providedIn: 'root',
})
export class ShellSettingsService {
  private readonly _themingService = inject(HyThemingService);

  // TODO: Remove this once Hyland UI add full support for dark theme
  /**
   * Currently, the hyland UI is not fully supporting the dark theme,
   * and in order to make the portal be more readable, we are going to force the light version
   */
  forceLightTheme() {
    this._themingService.setAutoTheme(false);
    this._themingService.setCurrentPickedTheme('hy-light-theme');
  }
}
