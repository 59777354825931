import { Injectable } from '@angular/core';
import { HyToastRef, HyToastService } from '@hyland/ui';

@Injectable({
  providedIn: 'root',
})
export class HxpToastService {
  constructor(private _toastService: HyToastService) {}

  success(text: string): HyToastRef {
    return this._toastService.success(text, {
      duration: 5000,
    });
  }

  warning(text: string): HyToastRef {
    return this._toastService.warning(text, {
      duration: 5000,
    });
  }

  error(text: string): HyToastRef {
    return this._toastService.error(text, {
      duration: 5000,
    });
  }
}
