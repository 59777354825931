import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { TranslocoModule } from '@jsverse/transloco';
import { PageDoesNotExistRoutingModule } from './page-does-not-exist-routing.module';
import { PageDoesNotExistView } from './page-does-not-exist.view';

@NgModule({
  declarations: [PageDoesNotExistView],
  imports: [CommonModule, PageDoesNotExistRoutingModule, MatButtonModule, MatCardModule, TranslocoModule],
})
export class PageDoesNotExistModule {}
