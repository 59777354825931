import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserGroupsGQL } from '@hxp/graphql';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { DisplayUserGroup } from '../../types/display-user-group';

@Component({
  templateUrl: './manage-role-user-groups.dialog.html',
  styleUrls: ['./manage-role-user-groups.dialog.scss'],
})
export class ManageRoleUserGroupsDialog implements OnInit, OnDestroy {
  isLoading = true;
  allUserGroups: DisplayUserGroup[] = [];
  filteredUserGroups: DisplayUserGroup[] = [];
  selectedList: DisplayUserGroup[] = [];
  searchValue$: Subject<string> = new Subject<string>();
  private readonly _unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _getUserGroups: UserGroupsGQL,
    @Inject(MAT_DIALOG_DATA) readonly data: { roleName: string; userGroupsForRole: DisplayUserGroup[] },
  ) {
    this._getUserGroups
      .watch({ where: undefined })
      .valueChanges.pipe(
        filter((resp) => !!resp.data),
        map((resp) => {
          this.isLoading = resp.loading;
          return resp.data.currentUser.homeAccount.account.groups as DisplayUserGroup[];
        }),
        takeUntil(this._unsubscribe$),
      )
      .subscribe((results: DisplayUserGroup[]) => {
        this.allUserGroups = [...results].sort((a, b) => {
          if (a.displayName < b.displayName) {
            return -1;
          }
          if (a.displayName > b.displayName) {
            return 1;
          }
          return 0;
        });
        this.filteredUserGroups = this.allUserGroups;
        this.selectedList = data.userGroupsForRole;
      });
  }

  ngOnInit() {
    this._handleSearchStream();
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  trackById(_index: number, item: DisplayUserGroup): string {
    return item.id;
  }

  _onSearch(searchValue: string): void {
    this._searchUserGroups(searchValue);
  }

  _onClear(): void {
    this.filteredUserGroups = this.allUserGroups;
  }

  onSelectedItemsChanged(selectedUsers: unknown[]) {
    this.selectedList = selectedUsers as DisplayUserGroup[];
  }

  private _handleSearchStream(): void {
    this.searchValue$.pipe(debounceTime(400), distinctUntilChanged()).subscribe((searchValue: string) => {
      this._searchUserGroups(searchValue);
    });
  }

  private _searchUserGroups(searchValue: string) {
    this.filteredUserGroups = this.allUserGroups.filter((ug) => ug.displayName.toLowerCase().includes(searchValue.toLowerCase()));
  }
}
