import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  AccountApp,
  AccountAppProvisioningStatus,
  SubscribedApp,
  SubscribedAppProvisioningStatus,
  SubscriptionDetail,
  SubscriptionStatus,
} from '@hxp/graphql';
import { TranslocoModule } from '@jsverse/transloco';

interface ProvisioningObj {
  provisioningStatus: SubscriptionDetail['status'] | SubscribedApp['provisioningStatus'] | AccountApp['provisioningStatus'];
  objectType: SubscriptionDetail['__typename'] | SubscribedApp['__typename'] | AccountApp['__typename'];
  environmentName?: string | null;
}

interface AccountAppProvisioningObj extends ProvisioningObj {
  provisioningStatus: AccountApp['provisioningStatus'];
  objectType: SubscriptionDetail['__typename'];
}

interface SubscribedAppProvisioningObj extends ProvisioningObj {
  provisioningStatus: SubscribedApp['provisioningStatus'];
  objectType: SubscribedApp['__typename'];
}

@Component({
  selector: 'hxp-admin-provisioning-status-display',
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule, TranslocoModule],
  templateUrl: './provisioning-status-display.component.html',
  styleUrls: ['./provisioning-status-display.component.scss'],
})
export class ProvisioningStatusDisplayComponent {
  @Input({ required: true })
  provisioningStatus!: SubscriptionStatus | SubscribedAppProvisioningStatus | AccountAppProvisioningStatus;

  @Input({ required: true })
  objectType!: SubscriptionDetail['__typename'] | SubscribedApp['__typename'] | AccountApp['__typename'];

  @Input({ required: true })
  environmentName?: string | null;

  get isAwaitingProvisioning() {
    if (this._isAccountApp()) {
      return this.provisioningStatus === AccountAppProvisioningStatus.Pending && this.environmentName;
    } else if (this._isSubscribedApp()) {
      return this.provisioningStatus === SubscribedAppProvisioningStatus.Pending && this.environmentName;
    } else {
      return this.provisioningStatus === SubscriptionStatus.Draft && this.environmentName;
    }
  }

  get isProvisioning() {
    if (this._isAccountApp()) {
      return this.provisioningStatus === AccountAppProvisioningStatus.Provisioning;
    } else if (this._isSubscribedApp()) {
      return this.provisioningStatus === SubscribedAppProvisioningStatus.Provisioning;
    } else {
      return this.provisioningStatus === SubscriptionStatus.Provisioning;
    }
  }

  private _isAccountApp(): this is AccountAppProvisioningObj {
    return this.objectType === 'AccountApp';
  }

  private _isSubscribedApp(): this is SubscribedAppProvisioningObj {
    return this.objectType === 'SubscribedApp';
  }
}
