import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { Observable, of } from 'rxjs';

export type PageDoesNotExistConfig = TranslationTextConfig | StaticTextConfig;

interface Elevate {
  readonly elevate?: boolean;
}

interface TranslationTextConfig extends Elevate {
  readonly textPath: string;
  readonly actionLink: string;
}

interface StaticTextConfig extends Elevate {
  readonly text: string;
  readonly actionLink: string;
}

const isTranslationTextConfig = (config?: PageDoesNotExistConfig): config is TranslationTextConfig => {
  return (config as TranslationTextConfig)?.textPath !== undefined;
};

@Component({
  templateUrl: './page-does-not-exist.view.html',
  styleUrls: ['./page-does-not-exist.view.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageDoesNotExistView {
  readonly text$?: Observable<string | undefined>;
  readonly link?: string;
  readonly elevate?: boolean;

  constructor(route: ActivatedRoute, transloco: TranslocoService) {
    const routeData = route.snapshot.data as PageDoesNotExistConfig | undefined;
    this.text$ = isTranslationTextConfig(routeData) ? transloco.selectTranslate(routeData.textPath) : of(routeData?.text);
    this.link = routeData?.actionLink;
    this.elevate = routeData?.elevate ?? false;
  }
}
