import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OnOffSwitchComponent, SharedLoadersModule } from '@hxp/kernel';
import { HyContentListModule, HyFeedbackBannerModule, HyGhostModule, HyMaterialModule, HyTagModule, HyTranslateModule } from '@hyland/ui';
// eslint-disable-next-line max-len
import { ProvisioningStatusDisplayComponent } from '../subscriptions/components/provisioning-status-display/provisioning-status-display.component';
import { AccountDetailsRoutingModule } from './account-details-routing.module';
import { AccountDetailsOverviewView } from './views/account-details-overview/account-details-overview.view';

@NgModule({
  imports: [
    AccountDetailsOverviewView,
    CommonModule,
    AccountDetailsRoutingModule,
    FormsModule,
    MatCardModule,
    MatTabsModule,
    MatInputModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    HyMaterialModule,
    HyContentListModule,
    HyGhostModule,
    HyFeedbackBannerModule,
    HyTagModule,
    HyTranslateModule,
    SharedLoadersModule,
    MatSidenavModule,
    OnOffSwitchComponent,
    ProvisioningStatusDisplayComponent,
  ],
})
export class AccountDetailsModule {}
