<ng-container>
  <mat-card *transloco="let t; read: 'nucleus.dashboard.subscription-cards'">
    <mat-card-header class="pt-0">
      <mat-card-title>{{ t('header') }}</mat-card-title>
    </mat-card-header>
    <mat-card-content class="pt-6">
      <div class="flex flex-col space-y-6" *ngIf="subscriptionsByEnvironment$ | async as subscriptionsByEnvironment; else ghost">
        <div data-testid="subscriptions-list" *ngFor="let subscriptionsInEnvironment of subscriptionsByEnvironment | keyvalue">
          <h3 data-testid="sub-list-item" class="mat-subtitle-1">{{ subscriptionsInEnvironment.value[0].environment?.name }}</h3>
          <div class="w-2/3 xl:w-full grid grid-cols-1 xl:grid-cols-2 gap-4">
            <mat-card appearance="outlined" *ngFor="let subscription of subscriptionsInEnvironment.value; let last = last">
              <mat-card-content>
                <div class="hy-text-subhead">{{ subscription.title }}</div>
                <div class="mat-body mb-4">{{ t('applications-subheader') }}</div>
                <div class="flex flex-col space-y-3">
                  <div *ngFor="let subscribedApp of subscription.subscribedApps">
                    <a
                      mat-button
                      color="primary"
                      [routerLink]="['account', 'environment', subscriptionsInEnvironment.key, 'apps', subscribedApp.id]"
                    >
                      {{ subscribedApp.app.localizedName }}
                    </a>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>

      <ng-template #ghost>
        <div class="ghost-wrap w-full xl:w-3/4 2xl:w-1/2">
          <hy-ghost-block class="ghost-card"></hy-ghost-block>
        </div>
      </ng-template>
    </mat-card-content>
  </mat-card>
</ng-container>
