import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HyFeedbackBannerModule } from '@hyland/ui';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'hxp-portal-empty-app-list',
  standalone: true,
  imports: [CommonModule, HyFeedbackBannerModule, TranslocoModule],
  templateUrl: './empty-app-list.component.html',
  styleUrls: ['./empty-app-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyAppListComponent {}
